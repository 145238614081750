import api from "../../../utils/api";

const createHomeTesting = async (data) => {
  const response = await api.post(`/homeTesting/save`, data);
  return response.data;
};

const getAllHomeTesting = async (data) => {
  const response = await api.get(`/homeTesting/getAll`, data);
  return response.data;
};
const getHomeTestingByPatientId = async (data) => {
  const response = await api.get(`/homeTesting/getHomeTestingByPatientId`, {
    params: data,
  });
  return response.data;
};
const patientHomeTestingSubscriptionSave = async (data) => {
  const response = await api.post(`/homeTesting/purchaseHomeTestingPlan`, data);
  return response.data;
};



const homeScreenService = {
  createHomeTesting,
  getAllHomeTesting,
  getHomeTestingByPatientId,
  patientHomeTestingSubscriptionSave
};

export default homeScreenService;
