import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { toast } from "react-toastify";
// import { jwtDecode } from "jwt-decode";

import { PATH } from "../utils/path";
import { ParentComponentWithSideBar } from "../components/Layout/ParentComponentWithSideBar";
import api from "../utils/api";
import { Notification } from "../components/GenericActions";
import { requestPermission } from "../firebase";
import { logoutUser } from "../app/features/auth/auth.slice";

export const socket = io("https://familydoc-socket.xeventechnologies.com/chat");

function withOrWithoutSIdebar() {
  switch (window.location.pathname) {
    case PATH.TELE_CONSULTATION:
      return true;
    case PATH.APPOINTMENT_NOTES:
      return true;
    case PATH.PATIENT_TELE_CONSULTATION:
      return true;
    default:
      return false;
  }
}
function completeProfileLayout() {
  switch (window.location.pathname) {
    case PATH.COMPLETE_PROFILE:
      return true;
    case PATH.PERSONAL_INFORMATION:
      return true;
    case PATH.MEDICAL_HISTORY:
      return true;
    case PATH.PHARMACY:
      return true;
    default:
      return false;
  }
}
function ProtectedRoute({ path, element }) {
  let withSidebar = withOrWithoutSIdebar();
  let withoutSidebar = completeProfileLayout();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // Utility Function to check token expiry
  // function isTokenExpired(token) {
  //   try {
  //     const decodedToken = jwtDecode(token);
  //     const currentTime = Date.now() / 1000; // Convert to seconds
  //     return decodedToken.exp < currentTime;
  //   } catch (e) {
  //     return true; // Treat as expired if decoding fails
  //   }
  // }

  // useEffect(() => {
  //   debugger
  //   const token = localStorage.getItem("family_doc_app");

  //   if (!token || isTokenExpired(token)) {
  //     Notification("Session expired. Please log in again.", false);

  //     requestPermission().then((currentToken) => {
  //       const finalData = {
  //         userId: user?.userId,
  //         deviceId: currentToken || "",
  //       };
  //       dispatch(logoutUser(finalData));
  //     });

  //     navigate(PATH.SIGNIN);
  //     return;
  //   }

  //   if (!user) {
  //     navigate(PATH.SIGNIN);
  //   }
  // }, [user, navigate, dispatch]);

  // useEffect(() => {
  //   debugger
  //   const token = localStorage.getItem("family_doc_app");

  //   if (!user || !token || isTokenExpired(token)) {
  //     // Notify and handle logout only if there is an authenticated user
  //     if (user) {
  //       Notification("Session expired. Please log in again.", false);

  //       requestPermission().then((currentToken) => {
  //         const finalData = {
  //           userId: user?.userId,
  //           deviceId: currentToken || "",
  //         };
  //         dispatch(logoutUser(finalData));
  //       });
  //     }
  //     navigate(PATH.SIGNIN);
  //   }
  // }, [user, dispatch, navigate]);

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        Notification("Login Again", false);
        requestPermission().then((currentToken) => {
          const finalData = {
            userId: user?.userId,
            deviceId: currentToken || "",
          };
          dispatch(logoutUser(finalData));
        });
      }

      let { message } = error?.response?.data;

      if (!message) {
        message =
          (error?.response &&
            error?.response?.data &&
            error?.response?.data?.message) ||
          error?.message ||
          error?.toString();
      }

      const errors = {
        errors: error?.response?.data?.errors,
        message,
      };

      toast.error(message);

      throw errors;
    }
  );

  useEffect(() => {
    if (!user) {
      navigate(PATH.SIGNIN);
    }
  }, [user, navigate]);

  return !withSidebar && !withoutSidebar ? (
    <ParentComponentWithSideBar>{element}</ParentComponentWithSideBar>
  ) : (
    element
  );
}

ProtectedRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default ProtectedRoute;
