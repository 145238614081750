import api from "../../../utils/api";

const createDoctorSchedule = async (data) => {
    const response = await api.post(`/doctorSchedule/create`, data);
    return response.data;
  };

  const getAllDoctorSchedule = async (data) => {
    const response = await api.post(`/doctorSchedule/getAll`, data);
    return response.data;
  };
  const updateDoctorSchedule = async (data) => {
    const response = await api.post(`/doctorSchedule/updateFee`, data);
    return response.data;
  };

  const getDoctorSchedule = async (data) => {
    const response = await api.get(`/doctorSchedule/get`, {
        params: data,
      });
    return response.data;
  };

  const getAllSlots = async (data) => {
    const response = await api.get(`/doctorSchedule/getAllSlots`, {
        params: data,
      });
    return response.data;
  };

  const deleteDoctorSchedule = async (data) => {
    const response = await api.delete("/doctorSchedule/delete", {
      params: data,
    } );
    return response.data;
  };

  const doctorSignature = async (data) => {
    const response = await api.post(`/doctorSchedule/doctorSignature`, data);
    return response.data;
  };

  const getRemainingAptPres = async (data) => {
    const response = await api.get("/appointment/remainingAppointmentAndPrescription", {
      params: data,
    } );
    return response.data;
  };

  const blockAndUnBlockSlots = async (data) => {
    const response = await api.post("/doctorSchedule/blockAndUnBlockSlots", data);
    return response.data;
  };

  const doctorSchedule = {
    createDoctorSchedule,
    getAllDoctorSchedule,
    getDoctorSchedule,
    getAllSlots,
    deleteDoctorSchedule,
    updateDoctorSchedule,
    doctorSignature,
    getRemainingAptPres,
    blockAndUnBlockSlots
  };
  
  export default doctorSchedule;