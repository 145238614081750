import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Suspense, useState } from "react";
import FullScreenLoader from "./authorization/FullScreenLoader";
import { BrowserRouter } from "react-router-dom";
import RouterConfig from "./RouterConfig";
import { Toast } from "react-bootstrap";
import { onMessageListener, requestPermission } from "./firebase";

function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  requestPermission();
  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <BrowserRouter>
        <RouterConfig />
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={30000}
          autohide
          animation
          style={{
            position: "absolute",
            top: 100,
            right: 20,
            minWidth: 350,
          }}
        >
          <Toast.Header className="justify-content-between align-items-center">
            {/* <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              /> */}
            <div className="d-flex flex-column">
              <strong>{notification.title}</strong>
              <small>just now</small>
            </div>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
        {/* <Toast /> */}
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
