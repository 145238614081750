import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import stiScreeningService from "./stiscreening.service";

const initialState = {
  stiScreeningPlans: null,
  stiScreeningList: [],
  subscriptionPlansCount: [],
  patientSubscriptionSave: null,
  getAllPatientPlanList: [],
  patientStiScreeningSubscriptionSave:null,
  stiScreeningOfSinglePatient: null,

  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createStiScreening = createAsyncThunk(
  "stiScreening/save",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await stiScreeningService.createScreening(finalData);
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllStiScreening = createAsyncThunk(
  "stiScreening/getAll",
  async (thunkAPI) => {
    try {
      return await stiScreeningService.getAllScreening();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification("Network Error", false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const allStiScreeningOfSinglePatient = createAsyncThunk(
  "stiScreening/getSTIScreeningByPatientId",
  async (finalData, thunkAPI) => {
    try {
      return await stiScreeningService.getStiScreeningByPatientId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const patientStiScreeningSubscriptionSaveData = createAsyncThunk(
  "stiScreening/purchaseSTIScreeningPlan",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await stiScreeningService.patientStiScreeningSubscriptionSave(
        finalData
      );
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);



const stiScreeningSlice = createSlice({
  name: "stiScreening",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(createStiScreening.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createStiScreening.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.stiScreeningPlans = action.payload.data;
      })
      .addCase(createStiScreening.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllStiScreening.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getAllStiScreening.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.stiScreeningList = action.payload.data;
      })
      .addCase(getAllStiScreening.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(patientStiScreeningSubscriptionSaveData.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(patientStiScreeningSubscriptionSaveData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientStiScreeningSubscriptionSave = action.payload.data;
      })
      .addCase(patientStiScreeningSubscriptionSaveData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(allStiScreeningOfSinglePatient.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(allStiScreeningOfSinglePatient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.stiScreeningOfSinglePatient = action.payload.data;
      })
      .addCase(allStiScreeningOfSinglePatient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
    
  },
});
export const { clearData } = stiScreeningSlice.actions;

export default stiScreeningSlice.reducer;
