import api from "../../../utils/api";

const createScreening = async (data) => {
  const response = await api.post(`/stiScreening/save`, data);
  return response.data;
};

const getAllScreening = async (data) => {
  const response = await api.get(`/stiScreening/getAll`, data);
  return response.data;
};

const patientStiScreeningSubscriptionSave = async (data) => {
  const response = await api.post(`/stiScreening/purchaseSTIScreeningPlan`, data);
  return response.data;
};

const getStiScreeningByPatientId = async (data) => {
  const response = await api.get(`/stiScreening/getSTIScreeningByPatientId`, {
    params: data,
  });
  return response.data;
};

const stiScreeningService = {
  createScreening,
  getAllScreening,
  patientStiScreeningSubscriptionSave,
  getStiScreeningByPatientId,
};

export default stiScreeningService;
